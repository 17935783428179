<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <h5 class="text-primary">Edit Tahun Anggaran</h5>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Tahun Anggaran" label-for="tahun anggaran">
              <b-form-input v-model="dataEdit.th" placeholder="Tahun Anggaran" disabled />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Keterangan" label-for="defaultLabel">
              <b-form-textarea v-model="dataEdit.keterangan" id="textarea-default" placeholder="Keterangan" rows="3" />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col md="6">
            <b-button variant="primary" size="sm" @click.prevent="validationForm">
              <feather-icon icon="SaveIcon" size="15"></feather-icon> Simpan
            </b-button>
            <b-button variant="warning" size="sm" class="ml-1">
              <feather-icon icon="RefreshCcwIcon" size="15"></feather-icon> Batal
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
  required, integer
} from '@validations'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
    BButton,
    FeatherIcon, ValidationProvider, ValidationObserver,
  },
  emits: ['updateData'],
  props: {
    dataEdit: {

    }
  },
  data() {
    return {
      required, integer, input: {}
    }
  }, methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.updateData(this.dataEdit);
          this.dataEdit = {}
        }
      })
    },
    updateData(value) {
      this.$emit("updateData", value);
    }
  },
  computed: {

  },
}
</script>