<template>
    <b-row>
        <b-col md="4" sm="4" class="my-1">
            <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50"></label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
            </b-form-group>
        </b-col>
        <b-col md="2" sm="8" class="my-1">

        </b-col>
        <b-col md="6" class="my-1">
            <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
                class="mb-0">
                <b-input-group size="sm">
                    <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
                </b-input-group>
            </b-form-group>
        </b-col>


        <b-col cols="12">
            <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
                :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                <template #cell(wbs)="data">
                    <b-row>
                        <b-col md="6">
                            <small class="text-primary">Tahun Anggaran</small>
                            <h3 class="text-primary"><strong>{{ data.item.th }}</strong><br><small>{{ data.item.keterangan
                            }}</small></h3>
                            <h6 class="text-warning"> <small>Status {{ data.item.status }}</small>
                            </h6>
                        </b-col>
                        <b-col md="6" v-if="data.item.status == 'Periode Selesai'" class="text-center">
                            <b-button variant="success" block size="sm"
                                @click="updateStatus(data.item, 'Realisasai Anggaran')">
                                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Update Status to<br>
                                Realisasi Anggaran
                            </b-button>
                        </b-col>
                        <b-col md="6" class="text-center">
                            <b-row>
                                <b-col md="6" v-if="data.item.status == 'Penyusunan Anggaran'">
                                    <b-button block variant="outline-warning" size="sm" @click="edtData(data.item)">
                                        <feather-icon icon="Edit3Icon" size="15"></feather-icon> Edit
                                    </b-button>
                                </b-col>
                                <b-col md="6" v-if="data.item.status == 'Penyusunan Anggaran'">
                                    <b-button block variant="outline-danger" size="sm" @click="HapusData(data.item)">
                                        <feather-icon icon="TrashIcon" size="15"></feather-icon> Hapus
                                    </b-button>
                                </b-col>
                            </b-row>
                            <!-- <small class="text-primary ">Update status menjadi</small> -->
                            <b-button variant="info" block size="sm" class="mt-1"
                                @click="updateStatus(data.item, 'Realisasai Anggaran')"
                                v-if="data.item.status == 'Penyusunan Anggaran'">
                                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' />Update Status to<br>
                                Realisasi Anggaran
                            </b-button>

                            <b-button variant="warning" block size="sm" class="mt-1"
                                @click="updateStatus(data.item, 'Periode Selesai')"
                                v-if="data.item.status == 'Realirusak  Anggaran'">
                                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Update Status to<br> Periode
                                Selesai
                            </b-button>
                            <b-button variant="info" block size="sm" class="mt-1"
                                @click="updateStatus(data.item, 'Penyusunan Anggaran')"
                                v-if="data.item.status == 'Realisasai Anggaran'">
                                <feather-icon icon="RefreshCcwIcon" class="mr-50" size='15' /> Update Status to<br>
                                Penyusunan Anggaran
                            </b-button>
                        </b-col>
                    </b-row>
                </template>
            </b-table>
        </b-col>
        <b-col col="6">
            Total Data {{ items.length }}
        </b-col>

        <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
                class="my-0" />
        </b-col>
    </b-row>
</template>

<script>
import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
} from 'bootstrap-vue'

export default {
    components: {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
    },
    props: {
        rsData: {}
    },
    data() {
        return {
            perPage: 3,
            pageOptions: [3, 5, 10, 100],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [{
                key: 'wbs',
                label: '',
                thStyle: {
                    display: 'none'
                }
            }],

        }
    },
    computed: {
        items: {
            get: function () {
                return this.rsData;
            },
            set: function (newValue) {
                return this.rsData = newValue;
            }
        },

        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({
                    text: f.label,
                    value: f.key
                }))
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length
    },
    emits: ["edtData", "HapusData", "updateStatus"],
    methods: {
        HapusData(value) {
            if (confirm("apakah anda yakin akan menghapus data ini..??") == true) {
                this.$emit('HapusData', value)
            }
        },
        updateStatus(value, menjadi) {
            if (confirm("apakah anda yakin akan update status data ini..??") == true) {
                this.$emit('updateStatus', value, menjadi)
            }
        },
        edtData(value) {
            this.$emit("edtData", value)
        },
        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>
<style>
.btn-block {
    display: block;
    width: 100%;
}
</style>