<template>
    <div>
        <b-row>
            <b-col md="6">
                <b-card>
                    <tabel_data :rsData=rsData @edtData=edtData @HapusData=HapusData @updateStatus=updateStatus>
                    </tabel_data>
                </b-card>
            </b-col>
            <b-col md="6">
                <b-card>
                    <form-add @simpanData=simpanData v-if="dataEdit.id == '0'"></form-add>
                    <form-edit @updateData=updateData :dataEdit=dataEdit v-if="dataEdit.id != '0'"></form-edit>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from '@/config/Axios';
import Base from '@/config/Mixins_base';
import tabel_data from './component/tabel.vue';
import {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
} from 'bootstrap-vue'
import FormAdd from './component/form.vue';
import formEdit from './component/formEdit.vue';
import { alpha } from 'vee-validate/dist/rules';

export default {
    components: {
        BCard,
        tabel_data,
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        FormAdd, formEdit,
    },
    mixins: [Base],
    data() {
        return {
            rsData: [],
            dataEdit: {
                id: "0"
            }
        }

    },
    computed: {},
    mounted() {
        this.loadData();
    },
    methods: {
        edtData(value) {
            this.dataEdit = value
        },
        async loadData() {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/pu/setup/tahun/load',
                data: {

                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.rsData = response.data.result;
                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async updateStatus(value, to) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/tahun/update_satatus',
                data: {
                    id: value.id,
                    keterangan: value.keterangan,
                    status: value.status,
                    th: value.th,
                    menjadi: to
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.loadData();
                    self.notification('Info', "info", response.data.message);
                    self.dataEdit = {
                        id: "0"
                    }

                }).catch(err => {
                    self.loadData();
                    self.notification('Info', "info", response.data.message);
                    self.dataEdit = {
                        id: "0"
                    }
                });
        },
        async HapusData(value) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/tahun/hapus',
                data: value,
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.loadData();
                    self.notification('Info', "info", response.data.message);
                    self.dataEdit = {
                        id: "0"
                    }

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async updateData(value) {
            const self = this;
            await axios({
                method: 'PUT',
                url: '/api/pu/setup/tahun/update_keterangan',
                data: value,
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.loadData();
                    self.notification('Info', "info", response.data.message);
                    self.dataEdit = {
                        id: "0"
                    }

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
        async simpanData(value) {
            const self = this;
            await axios({
                method: 'POST',
                url: '/api/pu/setup/tahun/insert',
                data: {
                    th: value.th,
                    keterangan: value.keterangan
                },
                headers: {
                    'Authorization': self.isAuthenticated
                }
            })
                .then(function (response) {
                    self.notification('info', "info", response.data.message);
                    self.loadData();

                }).catch(err => {
                    self.pesan = err.message;
                    self.notification('warning', "Error", err.message);
                });
        },
    },
}
</script>